import { ItemDTO } from '@generated/swagger/cat-search-api';
import { PriceDTO, ReturnItemDTO, StockDTO } from '@generated/swagger/inventory-api';

function mapAssortment(itemDto: ItemDTO): string {
  // input:
  // {
  //   "enabled": true,
  //   "key": "SOB",
  //   "value": "Basissortiment"
  // }
  // output:
  // Basissortiment|B
  return itemDto.features?.reduce((acc, curr) => acc + `${curr.value || ''}|${curr.key[curr.key.length - 1]}`, '');
}

function mapRetailPrice(itemDto: ItemDTO): PriceDTO {
  let availability = itemDto?.storeAvailabilities?.find((f) => !!f);

  if (!availability) {
    availability = itemDto?.catalogAvailability;
  }

  return availability.price;
}

export function fromItemDto(itemDto: ItemDTO, stock: StockDTO): ReturnItemDTO {
  return {
    // id: itemDto.ids?.dig,
    product: { ...itemDto.product, catalogProductNumber: String(itemDto.id) },
    assortment: mapAssortment(itemDto),
    retailPrice: mapRetailPrice(itemDto),
    stock: { id: stock.id },
    source: 'manually-added',
    predefinedReturnQuantity: 1,
  };
}
